// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-EsZJE"

const variantClassNames = {RMTMJ4BMT: "framer-v-1lfdte7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, logo, width, ...props}) => { return {...props, RjHY3scMX: logo ?? props.RjHY3scMX ?? {src: "https://framerusercontent.com/images/lTJrvV1xsPd6oXWXa8c9etY9U.svg"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;logo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RjHY3scMX, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "RMTMJ4BMT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lfdte7", className, classNames)} data-framer-name={"Clients Logo"} layoutDependency={layoutDependency} layoutId={"RMTMJ4BMT"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 42) - 0) - (((componentViewport?.height || 42) - 0) * 1)) / 2)))), pixelHeight: 42, pixelWidth: 126, sizes: `max(${componentViewport?.width || "100vw"}, 1px)`, ...toResponsiveImage(RjHY3scMX), ...{ positionX: "center", positionY: "center" }}} className={"framer-11ggfdo"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"m4m15PdqB"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EsZJE.framer-1irh35h, .framer-EsZJE .framer-1irh35h { display: block; }", ".framer-EsZJE.framer-1lfdte7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 42px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 126px; }", ".framer-EsZJE .framer-11ggfdo { flex: 1 0 0px; height: 100%; overflow: hidden; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EsZJE.framer-1lfdte7 { gap: 0px; } .framer-EsZJE.framer-1lfdte7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-EsZJE.framer-1lfdte7 > :first-child { margin-left: 0px; } .framer-EsZJE.framer-1lfdte7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 126
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"RjHY3scMX":"logo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framernh0YWAI3s: React.ComponentType<Props> = withCSS(Component, css, "framer-EsZJE") as typeof Component;
export default Framernh0YWAI3s;

Framernh0YWAI3s.displayName = "Helper/Clients Logo";

Framernh0YWAI3s.defaultProps = {height: 42, width: 126};

addPropertyControls(Framernh0YWAI3s, {RjHY3scMX: {__defaultAssetReference: "data:framer/asset-reference,lTJrvV1xsPd6oXWXa8c9etY9U.svg?originalFilename=Logo.svg&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(Framernh0YWAI3s, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})